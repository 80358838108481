$(function(){
    console.log("common.js"); 

    /* test 함수 */
    function msgFn(msg1, msg2){
        var _msg = msg1 + msg2; 
        return _msg; 
    }//msgFn - close
    $("body").addClass( msgFn("msg_","hi") );
    $("#wrap").addClass('wrapper');
}); 

